import { render, staticRenderFns } from "./assuredBook2.vue?vue&type=template&id=4dcad706&scoped=true"
import script from "./assuredBook2.vue?vue&type=script&lang=js"
export * from "./assuredBook2.vue?vue&type=script&lang=js"
import style0 from "./assuredBook2.vue?vue&type=style&index=0&id=4dcad706&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcad706",
  null
  
)

export default component.exports